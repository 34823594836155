@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	overflow: hidden;
}

body {
	overflow: hidden;
	position: relative;
	margin: 0;
	padding: 0;
}

input {
	@apply rounded outline-blue-600;
}

@-moz-document url-prefix() {
	.backdrop-blur-lg {
		@apply bg-gray-200/90;
	}
}